<!--
* @Author: tianzl
* @Date: 2023-10-27 10:18:17
* @LastEditors: tianzl
* @LastEditTime: 2023-10-27 10:18:17
* @Description: 重点关注
 -->
 <template>
  <div class="important-card">
    <p class="title">
      <img v-if="details.icon" :src="details.icon" class="img-title">
      {{ details.groupName || '' }}
    </p>
    <!-- 有subGroupName层级的 -->
    <span v-if="details.children.length&&details.children[0].subGroupName">
      <van-panel v-for="sub in details.children" :key="sub.subGroupName" :title="sub.subGroupName">
        <div class="container">
          <div v-for="(item,idx) in sub.children" :key="item.name" 
            :class="['item',idx!==sub.children.length-1&&(!judgeMobile || (judgeMobile&&(idx===0||idx%3!==0)))?'line':'']"
            @click="gotoPath(item)">
            <p class="label">{{ item.name }}</p>
            <p class="value" :style="{color:item.color||'#000'}">{{ item.count }}</p>
          </div>
        </div>
      </van-panel>
    </span>
    <!-- 没有subGroupName层级的 -->
    <span v-else>
      <div class="container">
          <div v-for="(item,idx) in details.children" :key="item.name" 
            :class="['item',idx!==details.children.length-1&&((!judgeMobile&&idx!==4) || (judgeMobile&&idx!==3&&idx!==7))?'line':'']"
            @click="gotoPath(item)">
            <p class="label">{{ item.name }}</p>
            <p class="value" :style="{color:item.color||'#000'}">{{ item.count }}</p>
          </div>
        </div>
    </span>
  </div>
 </template>
<script>
import { judgeMobile } from '@/utils/index'
import moment from 'moment'
export default {
  props:{
    details:{
      type:Object,
      default:()=>{}
    },
    active:{
      type: Number,
      default: 0
    }
  },
  computed:{
    judgeMobile,
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  methods:{
    gotoPath({ path,code ,agentId }){
      const urlParams = new URLSearchParams()
      urlParams.append('agentId',agentId)
      const query = this.$route.query
      Object.keys(query).forEach((key)=>{
        if (key!=='agentId') urlParams.append(key,query[key])
      })
      // 重点关注-即将逾期-线索跟进（30min内）需要带上跟进时间
      if (code === 'LEAD_FOLLOW_FUTURE'){
        const start = moment().format('YYYY-MM-DD HH:mm:ss')
        const end = moment().subtract(-30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        urlParams.append('start',start)
        urlParams.append('end',end)
      }
      // 首次待跟进
      if (code==='LEAD_FOLLOW'){
        path = `/clue-list?tabs=0,${this.active===0?1:this.active===3?20:25},20`
      }
      // 待跟进
      if (code==='LEAD_FOLLOW_AGAIN'){
        path = `/clue-list?tabs=0,${this.active===0?1:this.active===3?20:25},0&states=2000010,2000025`
      }
      // pdc
      if (code === 'APPOINT_PDC'){
        path = `/clue-list?tabs=0,${this.active===0?1:this.active===3?20:25},30`
      }
      // 试驾回访
      if (code==='OPPORTUNITY_DRIVE_FOLLOW'){
        path = `/clue-list?tabs=0,${this.active===0?1:this.active===3?20:25},35`
      }
      // 如果当前用户的最高权限是master，则跳转后要选中自己
      const { roleLogos,id } = this.getUserInfo
      if (roleLogos.includes('1014004')&&!roleLogos.includes('1014003')){
        urlParams.append('followUserIds',id)
      }
      let newUrl = `${location.protocol}//${location.host}${path}`
      if ( newUrl.indexOf('?')>-1 ) newUrl += `&${urlParams.toString()}`
      else newUrl += `?${urlParams.toString()}`
      location.href = newUrl
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  font-size: 16px;
  font-family: HYQiHei-60S, HYQiHei;
  font-weight: normal;
  color: #0D171A;
  line-height: 18px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  .img-title{
    width: 22px;
    height: 17px;
    object-fit: cover;
    margin-right: 5px;
  }
}
/deep/.van-cell{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
/deep/.van-cell-group{
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
/deep/.van-cell__title{
  font-size: 14px;
  font-family: HYQiHei-60S, HYQiHei;
  font-weight: normal;
  color: #0D171A;
  line-height: 16px;
}
.container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(79px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
  gap: 1px; 
  padding: 10px 16px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
}
.item{
  display: flex;
  flex-direction: column;
  min-width: 79px;
  margin-bottom: 8px;
  cursor: pointer;
}
.label,.value{
  text-align: center;
}
.label{
  font-size: 12px;
  font-family: HYQiHei-60S, HYQiHei;
  font-weight: normal;
  color: rgba(13,23,26,0.45);
  line-height: 22px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.value{
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FF7D00;
  line-height: 23px;
}
.line{
  position: relative;
  &::after{
    position: absolute;
    right: 0;
    top: 15px;
    width: 1px;
    height: 24px;
    background: #ebedf0;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    content: "";
  }
}
</style>