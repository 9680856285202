<!--
* @Author: tianzl
* @Date: 2023-10-27 10:18:17
* @LastEditors: tianzl
* @LastEditTime: 2023-10-27 10:18:17
* @Description: 今日完成情况
 -->
 <template>
    <div class="important-card">
      <p class="title">
        <img v-if="details.icon" :src="details.icon" class="img-title">
        {{ details.groupName || '' }}
      </p>
      <span>
        <div class="container">
            <div v-for="(item,idx) in details.children" :key="item.name" :class="['item',item.key]">
              <p class="label">{{ item.name }}</p>
              <p class="value" :style="{color:item.color||'#000'}">{{ item.count }}</p>
            </div>
          </div>
      </span>
    </div>
   </template>
<script>
import { judgeMobile } from '@/utils/index'
import moment from 'moment'
export default {
  props:{
    details:{
      type:Object,
      default:()=>{}
    }
  },
  computed:{
    judgeMobile,
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  methods:{
    
  }
}
</script>
  <style lang="less" scoped>
  .title{
    font-size: 16px;
    font-family: HYQiHei-60S, HYQiHei;
    font-weight: normal;
    color: #0D171A;
    line-height: 18px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    .img-title{
      width: 22px;
      height: 17px;
      object-fit: cover;
      margin-right: 5px;
    }
  }
  /deep/.van-cell{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  /deep/.van-cell-group{
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
  }
  /deep/.van-cell__title{
    font-size: 14px;
    font-family: HYQiHei-60S, HYQiHei;
    font-weight: normal;
    color: #0D171A;
    line-height: 16px;
  }
  .container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(79px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
    gap: 8px; 
    padding: 12px;
    margin-bottom: 16px;
    background: #fff;
    border-radius: 8px;
  }
  .item{
    display: flex;
    flex-direction: column;
    min-width: 79px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .label,.value{
    text-align: center;
  }
  .label{
    font-size: 12px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    line-height: 22px;
    height: 22px;
  }
  .value{
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 28px;
    height: 28px;
  }

  .newLead{
    background: linear-gradient(
        rgba(204, 221, 255, .5),
        rgba(204, 221, 255, .4) 25%,    /* 25% 的位置，颜色更浅 */
        rgba(204, 221, 255, .3) 50%,    /* 50% 的位置，更浅 */
        rgba(204, 221, 255, .2) 75%,    /* 75% 的位置，更浅 */
        rgba(204, 221, 255, .1)         /* 100% 的位置，最浅 */
    );
  }

  .newFollow{
    background: linear-gradient(
        rgba(255, 125, 0, .1),
        rgba(255, 125, 0, .08) 25%,    /* 25% 的位置，颜色更浅 */
        rgba(255, 125, 0, .06) 50%,    /* 50% 的位置，更浅 */
        rgba(255, 125, 0, .04) 75%,    /* 75% 的位置，更浅 */
        rgba(255, 125, 0, .02)         /* 100% 的位置，最浅 */
    );
  }

  .finishDrive{
    background: linear-gradient(
        rgba(255,237,182, .5),
        rgba(255,237,182, .4) 25%,    /* 25% 的位置，颜色更浅 */
        rgba(255,237,182, .3) 50%,    /* 50% 的位置，更浅 */
        rgba(255,237,182, .2) 75%,    /* 75% 的位置，更浅 */
        rgba(255,237,182, .1)         /* 100% 的位置，最浅 */
    );
  }

  .newAppoint{
    background: linear-gradient(
        rgba(209,240,222, .5),
        rgba(209,240,222, .4) 25%,    /* 25% 的位置，颜色更浅 */
        rgba(209,240,222, .3) 50%,    /* 50% 的位置，更浅 */
        rgba(209,240,222, .2) 75%,    /* 75% 的位置，更浅 */
        rgba(209,240,222, .1)         /* 100% 的位置，最浅 */
    );
  }

  .newOrder{
    background: linear-gradient(
        rgba(253, 110, 153, .3),
        rgba(253, 110, 153, .2) 25%,    /* 25% 的位置，颜色更浅 */
        rgba(253, 110, 153, .1) 50%,    /* 50% 的位置，更浅 */
        rgba(253, 110, 153, .05) 75%,    /* 75% 的位置，更浅 */
        rgba(253, 110, 153, .01)         /* 100% 的位置，最浅 */
    );
  }
  </style>