<!--
* @Author: tianzl
* @Date: 2023-10-26 17:29:33
* @LastEditors: tianzl
* @LastEditTime: 2023-10-26 17:29:33
* @Description: 我的待办
 -->
 <template>
  <div class="my-todo">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <!-- 时间选择 -->
      <div class="time-tabs">
        <van-tabs v-model="active" type="card" color="#B9921A" @change="getTodo">
          <van-tab v-for="e in tabs" :key="e.key" :name="e.key" :title="e.title"></van-tab>
        </van-tabs>
      </div>
      <ShopManagerTodo v-if="isShopManager" :details="details" :active="active"/>
      <ShopAssistantTodo v-else :details="details" :active="active"/>
      <Finish :details="finish"></Finish>
    </van-pull-refresh>
  </div>
 </template>
<script>
import ShopManagerTodo from './components/shop-manager-todo.vue'
import ShopAssistantTodo from './components/shop-assistant-todo.vue'
import TodoServices from '@/services/todoServices.js'
import Finish from './components/finish.vue'
import moment from 'moment'

// 字体颜色控制
const fontColor = {
  // 'LEAD_FOLLOW_FUTURE':'#FF7D00',
  'LEAD_RECYCLE_FUTURE':'#FF7D00',
  'LEAD_FOLLOW_OVERDUE':'#E4002C',
  'LEAD_STORE_OVERDUE':'#E4002C'
}
const pathMap = {
  'LEAD_FOLLOW_FUTURE': '/clue-list?tabs=0,1,1', // 重点关注-即将逾期-线索跟进（30min内）
  'LEAD_RECYCLE_FUTURE': '/clue-list?tabs=0,2,1&isFutureRecycle=1', // 重点关注-即将逾期-线索回收
  'LEAD_FOLLOW_OVERDUE': '/clue-list?tabs=0,2,1', // 重点关注-已逾期-待跟进线索
  'LEAD_STORE_OVERDUE': '/appointment?tabs=0,TIMEOUT_CONFIRMED', // 重点关注-已逾期--超时未到店
  'LEAD_TO_ASSIGN': '/clue-distribution?tabs=0,1', // 店长-我的待办-待分配线索
  'LEAD_TO_ASSIGN_DRIVE': '/clue-distribution?tabs=0,2',// 店长-我的待办-待分配预约试驾
  'LEAD_DEFEAT_REVIEW': '/clue-defeat', // 店长-我的待办-战败审核
  'LEAD_INVALID_REVIEW': '/invalid-manage',// 店长-我的待办-无效管理

  'APPOINT_FOLLOW': '/appointment?tabs=0,CONFIRMED', // 员工待办-预约接待
  'APPOINT_PDC': '/clue-list?tabs=0,1,3', // 员工待办-PDC
  'APPOINT_STORE': '/clue-list?tabs=0,1,6', // 员工待办-到店前一天跟进

  'APPOINT_FOLLOW_STORE': '/appointment?tabs=1,NO_ARRIVAL',// 员工待办-待到店 跳转到 【预约管理】-预约到店-今日的未到店 
  'APPOINT_FOLLOW': '/appointment?tabs=0,CONFIRMED',// 员工待办-待试驾 跳转到 【预约管理】-预约试驾-今日的未试驾
  'ORDER_LOCK': '/order-list?tabs=5', // 待锁单
  'ORDER_CONTRACT_PUSH':'/order-list?tabs=3',// 待推送合同
  'ORDER_CONTRACT_SIGN':'/order-list?tabs=4',// 待签署合同
  
  'APPOINT_TO_CONFIRM': '/appointment?tabs=0,TO_BE_CONFIRM', // 员工待办-试驾待确认
  'LEAD_FOLLOW': '/clue-list?tabs=0,1,1', // 员工待办-线索跟进
  'OPPORTUNITY_DRIVE_FOLLOW:': '/opportunityManage?tabs=0,1,9' // 员工待办-商家管理-今日待跟进-试驾回访
}

const iconMap = {
  '重点关注':require('../../images/important.png'),
  '今日待办':require('../../images/today.png'),
}

export default {
  components:{ ShopManagerTodo,ShopAssistantTodo,Finish },
  data(){
    return {
      details: [],
      isLoading: false,
      active: 0,
      tabs: [
        {
          title:'今日',
          key: 0
        },
        {
          title:'3日',
          key: 3
        },
        {
          title:'7日',
          key: 7
        },
      ],
      finish: {
        groupName: '今日完成情况',
        icon: require('../../images/finish.png'),
        children: [
          {
            name: '新增线索',
            key: 'newLead',
            count: '',
          },
          {
            name: '新增排程',
            key: 'newAppoint',
            count: '',
          },
          {
            name: '完成试驾',
            key: 'finishDrive',
            count: '',
          },
          {
            name: '跟进总量',
            key: 'newFollow',
            count: '',
          },
          {
            name: '新增订单',
            key: 'newOrder',
            count: '',
          }
        ]
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      from.name && vm.$store.commit('app/deleteKeepAlive', from.name)
    })
  },
  computed:{
    // 是否为店长
    isShopManager(){
      const { userInfo } =this.$store.getters
      const { roleLogos=[] } = userInfo
      return roleLogos.some((item)=>item === '1014003')
    }
  },
  created(){
    this.getTodo()
    this.getFinish()
  },
  methods:{
    async getTodo(){
      const params = { clientType:2 }
      params.planTime ={
        start: moment().format('YYYY-MM-DD 00:00:00'),
        end: moment().add(this.active, 'days').format('YYYY-MM-DD 23:59:59'),
      }
      const res = await TodoServices.getTodoList(params)
      // 组装数据
      const info = {}
      res.forEach(item => {
        const { groupName,subGroupName,name,count,code,url,agentId } = item
        if (!info[groupName]){
          info[groupName] = {
            groupName,
            children:[],
            icon:iconMap[groupName]
          }
        }
        if (subGroupName){
          let theOne = info[groupName].children.find(e=>e.subGroupName===subGroupName)
          if (!theOne) {
            theOne = {
              subGroupName,
              children:[]
            }
            info[groupName].children.push(theOne)
          }
          theOne.children.push({
            name,
            count,
            color: fontColor[code]||'',
            path: url,
            code,
            agentId
          })
        } else {
          info[groupName].children.push({ 
            name,
            count,
            color:fontColor[code]||'',
            path: url,
            code,
            agentId
          })
        }
      })
      this.details = Object.values(info)
      // console.log('====:',this.details)
    },
    async getFinish(){
      const res = await TodoServices.getTodayFinish()
      this.finish.children.forEach(e=>{
        e.count = res[e.key]
      })
    },
    async onRefresh(){
      this.active = 0
      await this.getTodo()
      await this.getFinish()
      this.isLoading = false
    }
  }
}
</script>
<style lang="less" scoped>
.my-todo{
  padding: 10px 16px;
}
</style>
<style lang="less">
.time-tabs{
  display: flex;
  justify-content: flex-end;
  .van-tabs__nav--card{
    width: 150px;
    margin: 0;
  }
}
</style>
