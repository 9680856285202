<!--
* @Author: tianzl
* @Date: 2023-10-26 18:06:38
* @LastEditors: tianzl
* @LastEditTime: 2023-10-26 18:06:38
* @Description: 店长的待办
 -->
 <template>
  <div>
    <Card v-for="item in details" :key="item.groupName" :details="item" :active="active"/>
  </div>
 </template>
<script>
import Card from './card.vue' 
export default {
  components:{ Card },
  props:{
    details:{
      type: Array,
      default:()=>[]
    },
    active:{
      type: Number,
      default: 0
    }
  }
}
</script>